import axios from "axios";
import router from "../router/index";
import { toastController } from "@ionic/vue";
import jwt_decode from "jwt-decode";

async function showToast(message, color, duration = 4000) {
  const toast = await toastController.create({
    message,
    color,
    duration,
  });
  toast.present();
}

const auth = {
  state: {
    user: null,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    UNSET_USER: (state) => {
      state.user = null;
    },
  },
  actions: {
    login: async ({ commit, state, dispatch }, cred) => {
      dispatch("setGlobalLoading", { status: true, message: "Logging In..." });
      try {
        const result = await axios.post(`/auth/attempt`, cred);
        const { user } = jwt_decode(result.data.access_token);
        commit("SET_USER", user);
        showToast("Login Successful", "success");
        router.push("/dashboard");
      } catch (err) {
        showToast("Login failed. Please Check your connection", "danger", 6000);
        console.log(err);
        dispatch("setGlobalLoading", { status: false });
      }
    },
    logout: ({ commit }) => {
      axios
        .post(`/auth/logout`)
        .then(() => {
          commit("UNSET_USER");
          router.push("/login");
          showToast("Logged Out", "success", 6000);
        })
        .catch((err) => {
          showToast("Logging Out Failed", "danger", 4000);
        });
    },
    refreshToken: async ({ commit, state, dispatch }) => {
      try {
        const result = await axios.post(`/auth/refresh`);
        if (result.data) {
          const { user } = jwt_decode(result.data.access_token);
          commit("SET_USER", user);
        } else {
          throw "No Token";
        }
      } catch (err) {
        if (state.user) {
          dispatch("logout");
        }
      }
    },
  },
};

export default auth;
