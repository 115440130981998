import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "../store/index";
import { toastController, loadingController } from "@ionic/vue";

async function showToast(message, color, duration = 4000) {
  const toast = await toastController.create({
    message,
    color,
    duration,
  });
  toast.present();
}
async function showLoading(message, duration = 10000) {
  const loading = await loadingController.create({
    message,
    duration,
  });
  await loading.present();
  return loading;
}

const routes = [
  {
    path: "",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: () => import("../views/DashboardPage.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/pages",
    component: () => import("../views/PagesPage.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/login",
    component: () => import("../views/UserLogin.vue"),
    beforeEnter: async (to, from, next) => {
      if (store.state.user) {
        next({
          path: "/dashboard",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/roadmap",
    component: () => import("../views/ProductRoadmap.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.initialize) {
    const loading = await showLoading("Please wait...");
    await store.dispatch("refreshToken");
    loading.dismiss();
    store.state.initialize = 1;
  }
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!store.state.user) {
      showToast("Please Login", "warning");
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
