import { createStore } from "vuex";
import router from "../router/index";
import auth from "./auth";

const store = createStore({
  state: {
    globalLoading: false,
    globalLoadingMessage: "Loading...",
    initialize: 0,
    ...auth.state,
  },
  mutations: {
    ...auth.mutations,
    SET_GLOBAL_LOADING: (state, status) => {
      state.globalLoading = status;
    },
    SET_GLOBAL_LOADING_MESSAGE: (state, message) => {
      state.globalLoadingMessage = message;
    },
  },
  actions: {
    ...auth.actions,
    setGlobalLoading: ({ commit }, { status, message }) => {
      commit("SET_GLOBAL_LOADING", status);
      if (message) {
        commit("SET_GLOBAL_LOADING_MESSAGE", message);
      } else {
        commit("SET_GLOBAL_LOADING_MESSAGE", "Loading...");
      }
    },
  },
  modules: {},
});
export default store;
