export default function extractCookie(name) {
  const str = name + "=";
  const cookie = decodeURIComponent(document.cookie);
  const cArr = cookie.split("; ");
  let value;
  cArr.forEach((val) => {
    if (val.indexOf(str) === 0) value = val.substring(str.length);
  });
  return value;
}
